import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import Layout from '../layout/layout';
import noSectionMedImage from '../assets/images/no-image-medium.png';
import ScrollAnimation from 'react-animate-on-scroll';
import CF from '../components/commonfunctions';
import { navigate } from "@reach/router";
import { graphql } from 'gatsby';
import moment from "moment";
import Search from '../components/search';
import Pagination from 'rc-pagination';
import ScaleLoader from "react-spinners/ScaleLoader";
import { share_url,s3_url } from '../config/configs'
import shareicon from '../assets/images/ic-share-arrow.svg';
import { isLoggedIn } from "../services/auth";

export const query = graphql`
{
  WordPress {
    menu(id: "dGVybTozMw==") {
      name
      menuItems {
        nodes {
          label
          cssClasses
          menu_data {
            externalLink
            sectionId
            svg {
              sourceUrl
            }
          }
        }
      }
    },
    themeGeneralSettings {
        theme_settings {
          highNotesGoodToKnow {
            ... on WordPress_Highnote {
              title
              date
              content
              uri
              slug
              high_notes {
                highNotesIcon{
                  sourceUrl
                }
                sliderImages {
                  image {
                    altText
                    sourceUrl
                  }
                }
              }
            }
          }
        }
      }
  }
  }
  `
let pageSize = 16;
const HighNotesCategories = ({ data, ...props }) => {
    const [list, listData] = useState([]);
    const [pageListAllData, setPageListAllData] = useState([]);
    const [page, newPage] = useState(1);
    const [total, newTotal] = useState();
    const [isOpen, setIsopen] = useState(false);
    const [scrollOffset, setOffset] = useState(0);
    const [viewAll, setViewAll] = useState(false);
    const [isSticky, setSticky] = useState(false);
    const [mainUrl, setMainUrl] = useState('');
    const [cat, setCat] = useState('');
    const [disableBlog, setDisableBlog] = useState();
    const settings = {
        dots: true,
        arrows: false,
        swipe: true,
        infinite: true,
        swipeToSlide: true,
        speed: 500,
        slidesToShow: 1,
    };

    const settings1 = {
        dots: false,
        arrows: true,
        swipe: true,
        infinite: true,
        swipeToSlide: true,
        speed: 500,
        slidesToShow: 1,
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [props])
    const handleScroll = (e) => {
        var headerH = document.querySelector('header') ? document.querySelector('header').offsetHeight : "";
        setSticky(window.scrollY > headerH ? true : false);
    };
    const handleCategory = (e, label, id) => {
        if (label == "Search") {
            setIsopen(!isOpen)
        }
        else if (label == 'Subscribe') {
            e.preventDefault();
            e.stopPropagation();
            const scrollSec = document.querySelector(e.target.hash);
            const headerOffset = document.getElementsByClassName("category-menu-outer") && document.getElementsByClassName("category-menu-outer")[0].clientHeight;
            const offsetH = (scrollSec.offsetTop - (!isSticky ? 108 + headerOffset : 108));
            setOffset(offsetH)
            window.scrollTo({ behavior: "smooth", top: offsetH });
        }
        else if (label === 'Show All') {
            navigate(`/highnotes-category?/showall`)
            highNotes('', '');
            setMainUrl('');
            setCat('Show All');
        }
        else if (label === "Category 1") {
            navigate(`/highnotes-category?/${id}/category1`)
            highNotes("", id);
            setMainUrl(id);
            setCat('Category 1');
        }
        else if (label === "Category 2") {
            navigate(`/highnotes-category?/${id}/category2`)
            highNotes("", id);
            setMainUrl(id)
            setCat('Category 2');

        }
        else if (label === "Category 3") {
            navigate(`/highnotes-category?/${id}/category3`)
            highNotes("", id);
            setMainUrl(id)
            setCat('Category 3');

        }
        else if (label === "Category 4") {
            navigate(`/highnotes-category?/${id}/category4`)
            highNotes("", id);
            setMainUrl(id)
            setCat('Category 4');
        }
    }

    useEffect(() => {
        let token = CF.getItem('token');
        // /*  if (!token) navigate('/login'); */

        let url = props && props.location && props.location.search && (props.location.search).split('/');
        highNotes("", url && url[1] ? url[1] : '');
        setMainUrl(url && url[1] ? url[1] : '');
        setCat(url && url[2] ? url[2] : '');
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);

    const highNotes = async (e,id) => {
        let iD = parseInt(id)
        let categoryID = [parseInt(iD)];
        let data = await fetch(s3_url+`/high_notes.json`)
        .then(response=>{
          return response.json()
        })
        var res = data.filter(function(e) {
            return e.categories_id.some(function(a) {
              return categoryID.indexOf(a) != -1
            })
          })

        let dataArray = [...res]
        setPageListAllData(res);
        let slicedArray = dataArray.slice(0, pageSize);
        listData(slicedArray)
        newPage(1);
        newTotal(Number(res.length))
      }

      const pagination = async (e) => {

        let dataArray = [...pageListAllData]
        let slicedArray = dataArray.slice(pageSize*(e-1), pageSize*e);
        listData(slicedArray)
        newPage(e ? e : 1);
      }

    let menuArr = data.WordPress.menu && data.WordPress.menu.menuItems && data.WordPress.menu.menuItems.nodes;
    let goodToKnow = data.WordPress && data.WordPress.themeGeneralSettings && data.WordPress.themeGeneralSettings.theme_settings && data.WordPress.themeGeneralSettings.theme_settings.highNotesGoodToKnow;

  if (!isLoggedIn() || disableBlog) {
     navigate('/');
     return null;
   } else if (isLoggedIn() && !disableBlog) {
    return (
        <React.Fragment>
            {list && list.length && Array.isArray(list) ?
                <Layout uma="inner-page" headerColor="header-highnotes" title={"News"} seoTitle={'UMX Categories'}>
                    {/* Banner Section Start */}
                    <section className="common-banner highnotes-banner">
                        <div className={`category-menu-outer highnotes-category-menu-outer ${isSticky ? 'header-category-fixed' : ''}`}>
                            <div className="container">
                                {/********************* Category Menu Start *********************/}
                                <nav className="category-menu">
                                    <ul>
                                        {menuArr && menuArr.length ? menuArr.map((menu, key) => {
                                            if (menu && menu.label !== "Search") {
                                                return (<li className="seven-category" key={key} onClick={(e) => handleCategory(e, menu.label, menu.menu_data && menu.menu_data.sectionId)}>
                                                    <a id="responsive-menu-trigger" href={menu && menu.label === 'Subscribe' ? '#conatctus' : menu.label === "Search" && "#"}>
                                                        <span className="category-img">
                                                            <img src={menu && menu.menu_data && menu.menu_data.svg && menu.menu_data.svg.sourceUrl} alt={menu.label} />
                                                        </span>
                                                        <span className="category-name">{menu.label}</span>
                                                    </a>
                                                </li>
                                                )
                                            }
                                        }) : null}
                                    </ul>
                                </nav>
                                {/********************* Category Menu End *********************/}
                            </div>
                        </div>
                        <div className="container">
                            <div className="common-banner-content">
                                <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                                    <h1 className="color-white">{cat ? <h1 className="color-white">{cat}</h1> : "Show All"}</h1>
                                </ScrollAnimation>
                            </div>
                        </div>

                        <Search page="highNotes" />
                        <div className="highnotes-slider-wrap">
                            <div className="container">
                                {/* Slider Section Start */}
                                <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                                    <div className="highnotes-main-slider">
                                        <Slider {...settings}>
                                            {
                                                goodToKnow && goodToKnow.length && goodToKnow.map((each, key) => {
                                                    let content = each && each.content ? each.content : "";
                                                    let des = content.substring(3, (each.content).length - 5);
                                                    let title = each && each.title ? each.title : "";
                                                    let date = each && each.date && moment(each.date).format('ll');
                                                    let image = each && each.high_notes && each.high_notes.sliderImages && each.high_notes.sliderImages[0] && each.high_notes.sliderImages[0].image ? each.high_notes.sliderImages[0].image.sourceUrl : noSectionMedImage;
                                                    let icon = each && each.high_notes && each.high_notes.highNotesIcon && each.high_notes.highNotesIcon.sourceUrl ? each.high_notes.highNotesIcon.sourceUrl : "";
                                                    let slug = each && each.slug ? each.slug : '';
                                                    return (
                                                        <div>
                                                            <div className="highnotes-slider-content" key={key}>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="resource-box">
                                                                            <div className="category-show">
                                                                                <img src={icon} />
                                                                            </div>
                                                                            <h6>{title}</h6>
                                                                            <span className="date">{date}</span>
                                                                            <div className="resource-desc">
                                                                                <p>{des}</p>
                                                                                <div className={des && des.length > 60 ? "more-share-sec more-share-sec-mt" : "more-share-sec"}>
                                                                                    {
                                                                                        des.length < 50 ? null : <a href="" onClick={() => navigate(`/blog-details/?slug=${slug}`)} className="continue-read-btn">CONTINUE READING</a>
                                                                                    }
                                                                                    <a href={"mailto:?Subject=X music&Body=" + share_url + `/blog-details?slug=${slug}`} className="share">
                                                                                        <span className="share">SHARE</span>
                                                                                        <span className="share-icon"><img src={shareicon} alt="Share" /></span>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="highnotes-slider-img">
                                                                            <img src={image} alt={title} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                                })}

                                        </Slider>
                                    </div>
                                    {/* Slider Section End */}
                                </ScrollAnimation>
                            </div>
                        </div>

                    </section>

                    <section className="highnotes-listing-main">
                        <div className="container">
                            <div className="common-banner-content">
                                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                                    <h1 className="color-white">{}</h1>
                                </ScrollAnimation>
                            </div>
                            {list && list.length && list.map((each, id) => {
                                let images = each && each.acf && each.acf.slider_images && each.acf.slider_images;
                                let icon = each && each.acf && each.acf.high_notes_icon && each.acf.high_notes_icon.url;
                                let content = each && each.content && each.content.rendered && (each.content.rendered).substring(3, (each.content.rendered).length - 5);
                                let name = each && each.title && each.title.rendered ? each.title.rendered : "";
                                let date = each && each.date ? moment(each.date).format('ll') : "";
                                let slug = each && each.slug ? each.slug : ''
                                if (id < 4 || viewAll) {
                                    return (<React.Fragment key={id}>
                                        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                                            <div className="highnotes-listing-block">
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <div className="highnotes-listing-img">
                                                            <Slider {...settings1}>
                                                                {images && images.length ? images.map((img, key) => {
                                                                    let photos = img && img.image && img.image.url ? img.image.url : noSectionMedImage;
                                                                    return (<div className="highnotes-listing-inner-img" key={key}>
                                                                        <img src={photos} alt={name} />
                                                                    </div>)
                                                                }) : <div className="highnotes-listing-inner-img">
                                                                        <img src={noSectionMedImage} alt={name} />
                                                                    </div>}
                                                            </Slider>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 offset-md-1">
                                                        <div className="highnotes-listing-content">
                                                            <div className="resource-box">
                                                                <div className="category-show">
                                                                    {/* <span className="category bg-yellow-cat">Documentation</span> */}
                                                                    <img src={icon} alt={name} />
                                                                </div>
                                                                <h6>{name}</h6>
                                                                <span className="date">{date}</span>
                                                                <div className="resource-desc">
                                                                    <p>{content}</p>
                                                                    <div className={content && content.length > 60 ? "more-share-sec more-share-sec-mt" : "more-share-sec"}>
                                                                        {
                                                                            content.length < 50 ? null : <a href="" onClick={() => navigate(`/blog-details/?slug=${slug}`)} className="continue-read-btn">CONTINUE READING</a>
                                                                        }
                                                                        <a href={"mailto:?Subject=X music&Body=" + share_url + `/blog-details?slug=${slug}`} className="share">
                                                                            <span className="share">SHARE</span>
                                                                            <span className="share-icon"><img src={shareicon} alt="Share" /></span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="resource-desc">
                                                                    <p><ReadMoreAndLess
                                                                        charLimit={60}
                                                                        readMoreText="CONTINUE READING"
                                                                        readLessText="LESS READING"
                                                                    >{content}</ReadMoreAndLess></p>
                                                                    <div className={content && content.length < 60 ? "more-share-sec more-share-sec-mt" : "more-share-sec"}>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ScrollAnimation>
                                    </React.Fragment>)
                                }
                            })}
                            {list && list.length > 4 && !viewAll ? <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                                <div className="view-all-sec">
                                    <a href="javascript:;" className="view-all-btn" onClick={() => setViewAll(true)}>{viewAll === false ? 'VIEW ALL' : ''}</a>
                                </div>
                            </ScrollAnimation> : ""}
                        </div>
                        {viewAll ? <div className="row">
                            <div className="col-md-12">
                                <Pagination showLessItems pageSize={pageSize} current={page} total={total} onChange={(e) =>  pagination(e)} locale={true} />
                            </div>
                        </div> : ""}
                    </section>
                </Layout> : <div className="product-loader"><ScaleLoader color={'#141618'} size={150} /></div>}
        </React.Fragment>
    );
  }
};
export default HighNotesCategories;
